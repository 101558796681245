@mixin unselectable() {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

@mixin no-pointer-events() {
  pointer-events: none;
}
