@import "bootstrap/scss/bootstrap";
@import "../variables";

.auth {

  h1{
    font-size: 28px;
    font-weight: 700;
  }

  h2{
    font-size: 20px;
    color: $blue;
    font-weight: 700;
    margin: 20px 0 60px 0;
  }

  &__header{
    background: $light-gray;

    img{
      width: 100px;
      height: 31px;

      @include media-breakpoint-up(lg) {
        width: 148px;
        height: 47px;
      }
    }

    @include media-breakpoint-up(lg) {
      text-align: center;
      width: 300px;
      height: 100%;
      position: fixed;
      padding: 70px 0 0 0;
      top: 0;
    }
  }

  &__content{
    padding: 30px 20px 0 !important;
    height: 100%;

    @include media-breakpoint-up(lg) {
      margin: 0 0 0 300px;
      padding: 70px 60px 0 !important;
    }

    .form-label{
      font-weight: 700;
    }
  }

  &__button {
    --border-radius: 9999px;
    height: 42px;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    --padding-start: 30px;
    --padding-end: 30px;
  }

  &__forgot{
    margin-top: 15px;
  }

  &__sign {
    margin-top: 60px;
    display: flex;
    align-items: center;

    .link {
      margin-left: 10px;
    }

    p{
      span{
        display: block;
      }
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;

    .checkbox {
      --border-width: 1px;
    }

    label{
      font-weight: 700;
      margin: 0 5px 0 20px;
      width: calc(100% - 40px);

      .link {
        color: $blue;
        text-decoration: none;
      }
    }

    .link{
      font-size: 12px;
    }
  }

  &__lang {
    margin-top: 50px;
    max-width: 150px;
  }
}


