.breadcrumb{
  a{
    text-decoration: none;
    color: black;
  }
  margin-bottom: 40px;
  @media screen and (max-width: $desktop-width) {
    margin-bottom: 10px;
  }
  font-weight: 700;

  i{
    margin: 0 10px;
    font-size: 14px;
  }

  span{
    color: #123A4C72;
    &.link{
      cursor: pointer;
    }
  }
}
