@import 'src/theme/variables';

body {
  --bs-font-sans-serif: #{$font-family};
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
}

:root {
  --ion-font-family: #{$font-family};
}

* {
  font-family: $font-family;
  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3 {
  font-weight: 700;
}

ion-button.link {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  height: 20px;
  margin: 1px 0;
  text-transform: none;
  font-weight: 700;
}

.sc-ion-input-md-h {
  --highlight-color-focused: transparent;
  --highlight-color-valid: transparent;
  --highlight-color-invalid: transparent;
  --highlight-color: transparent;
  caret-color: black!important;
}

.select {
  --padding-start: 10px;
  --padding-end: 10px;
  border: 1px solid #ced4da;
  background: $white;
  border-radius: 5px;
}

.content {
  height: 100%;
  width: 100%;

  @media (max-width: $desktop-width) {
    margin: 76px 0 50px 0;
    padding: 15px;
  }
}

ul {
  list-style-type: none;
  padding: 0 !important;
  margin: 0 !important;
}

ion-button {
  --box-shadow: none;
}

.button__rounded {
  --border-radius: 999px;
  --box-shadow: none;
  --padding-bottom: 18px;
  --padding-start: 10px;
  --padding-end: 10px;
  font-size: 16px;
  --padding-top: 18px;
  border-radius: 999px;
  width: 40px;
  height: 40px;

  &.light-blue {
    background: $light-blue;
    --background: #{$light-blue};
    --border-radius: 999px;
    color: $blue;
  }

  &.light-green {
    background: $light-green;
    --background: #{$light-green};
    --border-radius: 999px;
    color: $green;
  }

  &.light-gray {
    background: $light-gray;
    --background: #{$light-gray};
    --border-radius: 999px;
    color: $dark-blue;
  }

  &.light-red {
    background: $light-red;
    --background: #{$light-red};
    --border-radius: 999px;
    color: $red;
  }
}

button,
a {

  &:visited,
  &:active,
  &:focus {
    border: none;
    outline: none !important;
  }
}

.new {
  font-size: 16px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-left: 27px;
  --padding-right: 27px;
  --border-radius: 9999px;
  --box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-transform: none;
  font-weight: 700;
  width: 100%;
}

.modal-wrapper {
  @include media-breakpoint-up(md) {
    border-radius: 7px !important;
    height: 650px !important;
  }

  @media (max-width: $desktop-width) {
    position: fixed;
    top: 7%;
    height: 100% !important;
    width: 100% !important;
    border-radius: 15px !important;
  }
}

ion-modal.modal-DeleteProperty {
  --height: 45%;
  .modal-wrapper {
    height: 100% !important;
  }
}

ion-modal.choose-transaction-modal {
  --height: 400px;
  --width: 500px;

  .modal-wrapper {
    height: 400px !important;
  }
}

ion-modal.user-info-modal {
  --height: auto;

  .modal-wrapper {
    contain: content;
    height: fit-content !important;
    bottom: 0;
    top: auto;
    border-radius: 15px 15px 0 0 !important;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;

      .modal-content {
        overflow: auto;
      }
    }
  }
}

ion-modal.delete-modal {
  --height: auto;

  .modal-wrapper {
    contain: content;
    height: fit-content !important;
    bottom: 0;
    top: auto;
  }

  .ion-page {
    position: relative;
    contain: content;
    height: 300px;

    .modal-content {
      overflow: auto;
    }
  }
}

ion-modal.PaymentLinkCrudComponent {
  --height: 650px;
}

ion-modal.change-space-modal {
  --height: auto;
  --width: fit-content !important;
  --border-radius: 10px;
  --background:#F2F4F8;

  @media (max-width: $desktop-width) {
    --width: 90%!important;
  }

  .modal-wrapper {
    contain: content;
    height: fit-content !important;
    bottom: 0;
    top: auto;
    border-radius: 999px!important;
  }

  .ion-page {
    position: relative;
    contain: content;

    .modal-content {
      overflow: auto;
    }
  }
}

ion-modal.createProperty-modal {
  --height: auto;

  .modal-wrapper {
    width: 40%;
    contain: content;
    height: fit-content !important;
    bottom: 0;
    top: auto;
  }

  .ion-page {
    position: relative;
    contain: content;
    height: fit-content !important;

    .modal-content {
      overflow: auto;
    }
  }
}

ion-modal.fit-content {
  --height: auto;

  .modal-wrapper {
    contain: content;
    height: fit-content !important;
    bottom: 0;
    top: auto;
  }

  .ion-page {
    position: relative;
    contain: content;
    height: 400px;

    .modal-content {
      overflow: auto;
    }
  }
}

ion-modal.force-paiement {
  --height: auto;

  .modal-wrapper {
    contain: content;
    height: fit-content !important;
    bottom: 0;
    top: auto;
  }

  .ion-page {
    position: relative;
    contain: content;
    height: 480px;

    .modal-content {
      overflow: auto;
    }
  }
}

ion-modal.user-add {
  --height: auto;

  .modal-wrapper {
    contain: content;
    height: fit-content !important;
    bottom: 0;
    top: auto;
  }

  .ion-page {
    position: relative;
    contain: content;
    height: 550px;

    .modal-content {
      overflow: auto;
    }
  }
}

textarea {
  background-color: $white;
  color: $black;
  display: block;
  width: 100%;
  min-height: 100px;
  border: 1px solid $input-gray;
  border-radius: 5px;
  padding: 10px;
  outline: none;

  &:active,
  &:focus {
    border: 1px solid $input-gray;
    outline: none;
  }
}

sup {
  color: $blue;
}

.popover-content.sc-ion-popover-md {
  border-radius: 15px;
}

.popover__light {
  --width: 200px;
}

.desktop {
  @media screen and (max-width: $desktop-width) {
    display: none !important;
  }
}

.mobile {
  @media screen and (min-width: $mobile-width) {
    display: none !important;
  }
}

.tablet {
  @media screen and (min-width: $desktop-width) {
    display: none !important;
  }
}

.swiper-slide {
  width: auto;
}

.footer {
  @media screen and (min-width: $desktop-width) {
    display: none !important;
  }
}

.infos-modal {
  height: 100%;
  display: flex;
  padding: 50px 20px;
  align-items: center;
  flex-direction: column;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 9999px;
    --border-radius: 999px;
    font-size: 14px;
    color: $blue;
    font-weight: 700;
    text-decoration: none;
  }

  h1,
  .icon,
  .infos {
    padding-bottom: 30px;
  }
}

.alert-wrapper.sc-ion-alert-md {
  min-width: 350px;
}

.ng-select.ng-select-single {
  .ng-select-container {
    height: 46px;

    .ng-value-container .ng-input {
      top: 11px;
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 16px;
}

.is-invalid {

  input,
  .ng-select-container {
    border-color: $red !important;
  }
}

input {
  outline: none;
}

input.is-invalid {
  border-color: $red !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: $black !important;
}

input:focus-visible textarea:focus-visible {
  outline: none;
}

.alert-radio-label {
  white-space: break-spaces !important;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 0.5em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

input, textarea {
  &::placeholder {
    color: $dark-blue!important;
    opacity: 1!important;
    font-size: 16px!important;
  }
  caret-color: black!important;
}
