.data__container{
  h5{
    font-size: 12px;
    font-weight: 700;
  }

  .data{
    color: $dark-blue;
    font-weight: 700;
    padding: 20px;
    min-height: 62px;
    border-radius: 7px;
    font-size: 14px;
    border: 1px solid rgba(18,58,76,0.2);
  }

  ul {
    padding: 0 0 0 18px !important;
    list-style-type: circle;
    font-weight: 700;
    font-size: 14px;
  }
}

.link__container {
  h5{
    font-size: 12px;
    font-weight: 700;
  }

  p{
    color: $blue;
    margin: 0;
  }

  .data{
    display: flex;
    user-select: auto;
    @media screen and (max-width: $desktop-width) {
      display: block;
    }
    padding: 5px 20px;
    min-height: 62px;
    border-radius: 7px;
    align-items: center;
    border: 1px solid rgba(18,58,76,0.2);
    justify-content: space-between;
  }
}
