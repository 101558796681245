.tooltip__container {
  position: relative;

  .tooltip__item {
    position: absolute;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 13px;
    left: 0;
    font-weight: 700;
    background-color: #E2E7F0;
    bottom: -46px;
    text-align: center;
    z-index: 999;

    &.text__bottom {
      bottom: -76px;
    }
  }

  .tooltip__triangle{
    position: absolute;
    top: -6px;
    left: calc(50% - 3px);
    width: 0;
    height: 0;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #E2E7F0 transparent;
    border-style: solid;
  }
}
