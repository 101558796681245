/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Bootstrap */
@import 'bootstrap/scss/bootstrap';

/* Fonts */
@import 'theme/fontawesome';
@import 'theme/utils/mixins';

@import '@ng-select/ng-select/themes/default.theme.css';
@import 'flatpickr/dist/flatpickr.css';

/* Components */
@import 'theme/variables';
@import 'theme/default';
@import 'theme/auth/auth';
@import 'theme/auth/sign';
@import 'theme/tooltip';

@import 'theme/breadcrumb';
@import 'theme/data';
@import 'theme/bond/bond';
@import 'theme/payment/payment';
@import 'theme/payment/paymentV2';
@import 'theme/profil/profil';
