@import 'src/theme/variables';

.Payment {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;

  &__Outlet {
    width: 100%;


    @media (max-width: $desktop-width) {
      overflow-y: auto!important;
      height: calc(100svh - 70px);
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  &__Auth {
    width: 100%;

    &__Header {
      display: flex;
      width: 100%;
      height: 70px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      background: #F2F4F8;
      z-index: 11111111;

      @media (max-width: $desktop-width) {
        display: flex;
        flex-direction: column;
        justify-content: center!important;
        align-items: flex-start;
        padding: 10px;

        .Step {
          display: flex;
          justify-content: flex-end!important;
        }
      }

      &__Container {
        display: none;
        height: 49.227px;
        padding: 1px 185px 1px 50px;
        align-items: center;

        @media (min-width: $desktop-width) {
          display: flex!important;
        }

        &:last-child {
          padding: 1px 54px 1px 50px !important;
        }

        &__Logo {
          width: 148px;
          height: 47.227px;

          a {
            text-decoration: none;
            cursor: pointer;
          }

          a img {
            width: 100%;
          }
        }

        &__Lang {
          display: inline-flex;
          margin-left: 20px;
          padding: 5px 0;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &__Ariane {
          display: flex;
          align-items: flex-start;
          position: relative;
          gap: -122px;

          &__Separate {

            &__First, &__Second {
              top: -4px;
              position: absolute;
              width: calc(100% / 3);
              height: 5px;
              border: none;
              background-color: #8196A1;
              z-index: 998;
              opacity: 1;
            }

            &__First {
              left: 60px;
              background-color: $blue;
            }

            &__Second {
              right: 60px;
            }
          }

          &__Item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            z-index: 999;

            span:first-child {
              display: flex;
              width: 30px;
              height: 30px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 15px;
              border: 0.4px solid #8196A1;
              background: #FFF;
            }

            span:last-child {
              display: flex;
              width: 144.8px;
              padding: 0 8px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 4px;
              border-radius: 8px;
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .active {
              color: $blue !important;
              border: 0.4px solid $blue !important;
            }

            .activated {
                color: white !important;
                background-color: $blue !important;
            }

            .disabled {
              color: #8196A1 !important;
            }
          }
        }
      }
    }

    &__HeaderMobile {

      &__Container {
        width: 100%;
        display: none;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $desktop-width1) {
          display: flex;
        }

        &__Back {
          width: calc(100% / 3);
          display: flex;
          justify-content: flex-start;
          padding-left: 10px;
          align-items: center;

          i {
            font-size: 41px;
            --fa-primary-color: darkgreen;
            --fa-secondary-color: silver;
          }
        }

        &__Logo {
          width: 100px;
          flex-shrink: 0;
          padding-left: 10px;

          a {
            text-decoration: none;
            cursor: pointer;
          }

          a img {
            width: 100%;
          }
        }

        &__Lang {
          display: inline-flex;
          padding: 5px 0;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          width: calc(100% / 3);
        }

        &__Step {
          width: calc(100% / 3);
            display: flex;
            flex-direction: column;

          h1 {
            color: #1579DB;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 0;
            margin: 0;
            text-align: center;

            @media (max-width: $desktop-width) {
              font-size: 16px;
            }
          }

          p {
            color: #0A2540;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0;
            margin: 0;
            text-align: center;
          }
        }
      }
    }

    &__Content {
      display: flex;
      width: 843px;
      padding: 40px 0;
      margin: 0 auto;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      @media(max-width: $desktop-width) {
        width: 100%;
        padding: 40px 20px;
      }

      .InvalidEmailAuth {
        width: 100%;

        &__Header {
          margin-bottom: 20px;

          h1 {
            color: #0A2540;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 20px;
          }

          h2 {
            color: #1579DB;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        &__Form {
          &__Input {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
            align-self: stretch;
            margin-bottom: 20px;
            position: relative;

            label {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            input {
              display: flex;
              padding: 8px 20px;
              align-items: center;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid #D4D4D4;
              background-color: white;
              -webkit-appearance: none; /* Supprime l'apparence par défaut de Safari */
              appearance: none;
            }

            &__Forget {
              align-self: stretch;
              color: #1579DB;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-decoration-line: underline;
              margin-bottom: 20px;
              cursor: pointer;
            }

            &__Password {
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid #D4D4D4;

              input {
                border: transparent;
                background-color: white;
                -webkit-appearance: none; /* Supprime l'apparence par défaut de Safari */
                appearance: none;
              }

              &__Toggle {
                padding: 0 20px;

                i {
                  color: #8ea1ab;
                  cursor: pointer;
                }
              }
            }
          }

          &__Button {
            @media(max-width: $desktop-width) {
              width: 100%;
              button  {
                width: 100%;
              }
            }
          }

          &__ChangeMail {
            align-self: stretch;
            color: #1579DB;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration-line: underline;
            margin-bottom: 20px;
            cursor: pointer;
          }

          &__Validation {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            margin-bottom: 20px;

            &__Checkbox {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              gap: 5px;

              input[type="checkbox"] {
                display: flex;
                min-width: 15px;
                height: 15px;
                flex-direction: column;
                align-items: flex-start;
                -webkit-appearance: none; /* Supprime l'apparence par défaut de Safari */
                appearance: none;
                background-color: white;
                border: 1px #D4D4D4 solid;
                border-radius: 3px; /* Pour arrondir légèrement les bords */
                cursor: pointer;
                position: relative;
              }

              input[type="checkbox"]:checked {
                background-color: $blue; /* Change la couleur du fond au clic */
                border-color: $blue;
              }

              input[type="checkbox"]:checked::after {
                content: "✔"; /* Ajoute un symbole de check */
                color: white;
                font-size: 12px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              label {
                color: #0A2540;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }

  &__Infos {
    width: 100%;
    display: inline-flex;
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h1 {
      color: #0A2540;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: $desktop-width) {
        width: 90%;
      }
    }

    p {
      color: #0A2540;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media (max-width: $desktop-width) {
        width: 90%;
      }
    }

    &__Steps {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      @media (max-width: $desktop-width) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      &__Step {
        display: flex;
        width: 320px;
        padding: 10px;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        @media (max-width: $desktop-width) {
          width: 90%;
          padding: 0;
        }

        &__Container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          @media (max-width: $desktop-width) {
            flex-direction: row;
          }
        }

        &__Number {
          display: flex;
          width: 50px;
          height: 50px;
          padding: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 25px;
          border: 1px solid #1579DB;
          background: #fff;
          color: #1579DB;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

            @media (max-width: $desktop-width) {
                width: 30px;
                height: 30px;
                font-size: 20px;
            }
        }

        &__Resume {
          align-self: stretch;
          color: #1579DB;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          @media (max-width: $desktop-width) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: start;
            font-size: 14px;
          }
        }

        &__Details {
          align-self: stretch;
          color: #0A2540;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          @media (max-width: $desktop-width) {
            text-align: start;
          }
        }
      }
    }

    &__Button {
      display: flex;
      height: 36px;
      padding: 10px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 999px;
      background: #1579DB;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;

      @media (max-width: $desktop-width) {
        width: 90%;
      }
    }

    small {
      color: #0A2540;
      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 300;
      line-height: normal;
    }
  }

  &__Informations {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    overflow-y: auto!important;

    @media (min-width: $desktop-width) {
      height: calc(100vh - 70px); // 70px is the height of the header
    }

    @media (max-width: $desktop-width) {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px;
    }

    &__Form {
      display: flex;
      width: 628px;
      padding: 40px 0;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      margin: 0 auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @media (max-width: $desktop-width) {
        width: 100%;
        padding: 20px 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &__Errors {
        width: 628px;
        background-color: $light-red;
        border-radius: 10px;
        color: $red;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        margin: 0 auto 10px;

        @media (max-width: $desktop-width) {
          width: 100%;
        }

        i {
          font-size: 27px;
          padding-right: 25px;
        }
        p {
          margin: 0;
          font-size: 12px;
          font-weight: 600;
        }
      }

      &__Personal {
        display: flex;
        padding: 10px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
      }

      &__Document {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
      }

      &__Reservation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
      }

      h1, h2, h3 {
        color: #0A2540;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        padding: 0;
      }

      &__Button {
        display: flex;
        width: 158px;
        height: 36px;
        padding: 10px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 999px;
        background: #1579DB;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;

        &__Next {
          @media (max-width: $desktop-width) {
            width: 100%;
          }
        }
      }

      .ContainerDocument {
        flex-direction: column;
      }

      &__Container {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;

        @media (max-width: $desktop-width) {
          flex-direction: column;
          padding: 0!important;
          gap: 10px;

          .Phone {
            gap: 5px;
          }
        }

        .Phone {
          gap: 25px;
        }

        &__Input {
          display: flex;
          width: 300px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 5px;



          @media (max-width: $desktop-width) {
            width: 100%;
            flex-direction: column;
          }

          label {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            color: #0A2540;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          input, select {
            height: 34px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid #D4D4D4;
            padding: 0 10px 0 8px;
            background-color: white !important;
            -webkit-appearance: none; /* Supprime l'apparence par défaut de Safari */
            appearance: none;
          }

          ng-select{
            width: 100%;
            .ng-select-container {
              height: 34px !important;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid #D4D4D4;
              padding: 0 10px 0 8px;
              -webkit-appearance: none;
              appearance: none;
              align-items: center !important;
              .ng-value-container .ng-input {
                top: 0 !important;
                input{
                  background-color: transparent !important;
                }
              }
            }
          }

          select {
            padding: 0;
          }
        }

        &__DatePicker {
          display: flex;
          width: 300px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 5px;

          @media (max-width: $desktop-width) {
            width: 100%;
          }

          &__Header {
            display: flex;
            gap: 10px;

            @media (max-width: $desktop-width) {
              width: 100%;
            }

            label {
              font-size: 11px !important;
              font-weight: 400 !important;
            }

            &__Year {
              width: 120px;

              @media (max-width: $desktop-width) {
                width: 40%;
              }
            }
            &__Month, &__Day {
              width: 80px;

              @media (max-width: $desktop-width) {
                width: 30%;
              }
            }
          }

          &__Body {
            display: flex;
            gap: 10px;

            @media (max-width: $desktop-width) {
              width: 100%;
            }
            &__Year {
              width: 120px;

              @media (max-width: $desktop-width) {
                width: 40%;
              }
            }
            &__Month, &__Day {
              width: 80px;

              @media (max-width: $desktop-width) {
                width: 30%;
              }
            }
          }

          @media (max-width: $desktop-width) {
            width: 100%;
            flex-direction: column;
          }

          label {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            color: #0A2540;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          input, select {
            height: 34px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid #D4D4D4;
            padding: 0 10px 0 8px;
            background-color: white;
            -webkit-appearance: none; /* Supprime l'apparence par défaut de Safari */
            appearance: none;
          }

          select {
            padding: 0;
          }
        }

        &__Document {
          width: 100%;
        }
      }
    }

    &__Infos {
      display: flex;
      width: 413px;
      padding: 40px 20px 20px 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border-radius: 10px;

      @media (max-width: $desktop-width) {
        width: 100%;
        height: auto;
        padding: 0;
      }

      .isInfosOpen {
        display: flex !important;
      }

      &__RespToggle {
        display: none;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        user-select: none;
        padding: 0;

        h1 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 0;
          margin: 0;
        }

        p {
          color: #1579DB;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 0;
          margin: 0;

          i {
            color: #1579DB;
            font-size: 14px;
            padding-left: 10px;
          }
        }

        @media (max-width: $desktop-width) {
          display: flex;
        }
      }

      &__Owner {
        display: flex;
        padding: 10px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 10px;

        @media (max-width: $desktop-width) {
          display: none;
        }

        h3 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__Content {
          display: flex;
          padding: 0 15px;
          align-items: center;
          gap: 25px;

          &__Avatar {
            width: 100px;
            height: 100px;
          }
          &__Infos {
            display: flex;
            height: 100px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            strong {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
            p {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0!important;

              strong {
                color: #0A2540;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
      }
      &__Tenant {
        display: flex;
        padding: 10px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;

        @media (max-width: $desktop-width) {
          display: none;
        }

        h3 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p {
          color: #0A2540;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0!important;
        }
      }
      &__Deposit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        @media (max-width: $desktop-width) {
          display: none;
        }

        h3 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        &__Card {
          display: flex;
          align-items: center;
          align-self: stretch;
          &__Container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            &__Header {
              display: flex;
              height: 50px;
              padding: 15px 10px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 10px 10px 0 0;
              background: #D9E9F9;
              p {
                color: #0A2540;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0!important;
              }
            }
            &__Body {
              display: flex;
              padding: 15px 20px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 0 0 10px 10px;
              border: 1px solid #D9E9F9;
              background: #FFF;
              p {
                flex: 1 0 0;
                color: #0A2540;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0!important;
              }
            }
          }
        }
      }
    }

    &__Container {
      width: calc(100% - 400px);

        @media (max-width: $desktop-width) {
            width: 100%;
        }
    }
  }

  &__Transactions {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .Link {
      color: #0D6EFD;
    }

    overflow-y: auto!important;

    @media (min-width: $desktop-width) {
      height: calc(100vh - 70px); // 70px is the height of the header
    }

    @media (max-width: $desktop-width) {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px;
    }

    &__Form {
      display: flex;
      width: 628px;
      padding: 40px 0;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      margin: 0 auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: $desktop-width) {
        width: 100%;
        padding: 20px 0;
      }

      &__Errors {
        width: 628px;
        background-color: $light-red;
        border-radius: 10px;
        color: $red;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        margin: 0 auto 10px;

        @media (max-width: $desktop-width) {
          width: 100%;
        }

        i {
          font-size: 27px;
          padding-right: 25px;
        }
        p {
          margin: 0;
          font-size: 12px;
          font-weight: 600;
        }
      }

      &__TopInfos {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      &__Shared {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        h1 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .Choose {
          background: #D9E9F9 !important;
        }

        &__Choice1, &__Choice2 {
          display: flex;
          padding: 10px;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          border-radius: 10px;
          cursor: pointer;
          user-select: none;

          input {
            padding: 20px;
            background-color: white;
            -webkit-appearance: none;
            appearance: none;
          }

          &__Text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;

            p {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            span {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              span {
                color: #1579DB;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-decoration-line: underline;
              }
            }
          }
        }

        &__Choice2 {
          &__Divide {
            display: flex;
            align-items: center;
            gap: 10px;

            .Gray {
                color: #8196A1;
            }

            i {
                font-size: 15px;
            }

            p {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
              padding: 0;

              &:last-child {
                color: #8196A1;
                font-size: 16px;
                font-weight: 300;
              }
            }
          }
        }
      }

      &__Link {
        display: flex;
        align-items: center;
        align-self: stretch;

        @media (max-width: $desktop-width) {
          flex-direction: column;
        }

        &__Header {
          display: flex;
          width: 250px;
          padding: 15px 10px;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          border-radius: 10px 0 0 10px;
          background: #D9E9F9;

          @media (max-width: $desktop-width) {
            width: 100%;
            border-radius: 10px 10px 0 0;
          }

          p {
            color: #0A2540;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            padding: 0;
          }
        }

        &__Body {
          display: flex;
          padding: 15px 20px;
          justify-content: space-between;
          align-items: center;
          flex: 1 0 0;
          border-radius: 0 10px 10px 0;
          border: 1px solid #D9E9F9;
          background: #FFF;

          @media (max-width: $desktop-width) {
            width: 100%;
            border-radius: 0 0 10px 10px;
          }

          p {
            color: #0A2540;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            padding: 0;
          }
          &:first-child p {
            color : #1579DB!important;
          }
          i {
            color: #1579DB;
            font-size: 17.5px;
            cursor: pointer;
          }
        }
      }

      &__Part {
        display: flex;
        padding: 10px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        h1 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__Payfor {
          display: flex;
          align-items: center;
          align-self: stretch;

          @media (max-width: $desktop-width) {
            flex-direction: column;
          }

          &__Header {
            display: flex;
            width: 250px;
            padding: 15px 10px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-radius: 10px 0 0 10px;
            background: #D9E9F9;

            @media (max-width: $desktop-width) {
              width: 100%;
              border-radius: 10px 10px 0 0;
            }

            p {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin: 0;
              padding: 0;
            }
          }

          &__Body {
            display: flex;
            padding: 15px 20px;
            align-items: center;
            flex: 1 0 0;
            border-radius: 0 10px 10px 0;
            border: 1px solid #D9E9F9;
            background: #FFF;

            @media (max-width: $desktop-width) {
              width: 100%;
              border-radius: 0 0 10px 10px;
            }

            p {
              color: #0A2540;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
              padding: 0;
            }

            &__Container {
              display: flex;
              align-items: center;
              gap: 10px;

              .Gray {
                color: #8196A1;
              }
              i {
                font-size: 15px;
                cursor: pointer;
              }

              p {
                color: #0A2540;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
                padding: 0;
              }
            }

          }
        }
      }

      &__Card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        h1 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__Container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 5px;
          align-self: stretch;

          &__Card {
            display: flex;
            padding: 8px 20px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid #1579DB;
            user-select: none;
            cursor: pointer;

            &__Radio {
              width: 20px;
              height: 20px;
            }

            i {
              font-size: 25px;
              @media (max-width: $desktop-width) {
                font-size: 38.5px;
                padding: 0 10px;
              }
            }

            p {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
              padding: 0;

              @media (max-width: $desktop-width) {
                text-align: center;
              }
            }
          }

          &__AddCard {
            display: flex;
            height: 36px;
            padding: 10px 50px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 999px;
            border: 1px solid #1579DB;
            background: #FFF;
            color: #1579DB;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (max-width: $desktop-width) {
              width: 100%;
            }
          }
        }
      }

      &__Button {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        @media (max-width: $desktop-width) {
          gap: 0;
          justify-content: center;
        }

        &__Back {
          display: flex;
          width: 169px;
          height: 36px;
          padding: 10px 50px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 999px;
          border: transparent;
          background: #D9E9F9;
          color: #1579DB;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          cursor: pointer;

          @media (max-width: $desktop-width1) {
            display: none;
          }
        }

        &__Next {
          @media (max-width: $desktop-width) {
            width: 100%;
          }
        }
      }
    }

    &__Infos {
      display: flex;
      width: 413px;
      padding: 40px 20px 20px 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border-radius: 10px;

      @media (max-width: $desktop-width) {
        width: 100%;
        height: auto;
        padding: 0;
      }

      .isInfosOpen {
        display: flex !important;
      }

      &__RespToggle {
        display: none;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        user-select: none;
        padding: 0;

        h1 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 0;
          margin: 0;
        }

        p {
          color: #1579DB;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 0;
          margin: 0;

          i {
            color: #1579DB;
            font-size: 14px;
            padding-left: 10px;
          }
        }

        @media (max-width: $desktop-width) {
          display: flex;
        }
      }

      &__Owner {
        display: flex;
        padding: 10px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 10px;

        @media (max-width: $desktop-width) {
          display: none;
        }

        h3 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__Content {
          display: flex;
          padding: 0 15px;
          align-items: center;
          gap: 25px;

          &__Avatar {
            width: 100px;
            height: 100px;
          }
          &__Infos {
            display: flex;
            height: 100px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            strong {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
            p {
              color: #0A2540;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0!important;

              strong {
                color: #0A2540;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
      }
      &__Tenant {
        display: flex;
        padding: 10px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 10px;

        @media (max-width: $desktop-width) {
          display: none;
        }

        h3 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p {
          color: #0A2540;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0!important;
        }
      }
      &__Deposit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        @media (max-width: $desktop-width) {
          display: none;
        }

        h3 {
          color: #0A2540;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        &__Card {
          display: flex;
          align-items: center;
          align-self: stretch;
          &__Container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            &__Header {
              display: flex;
              height: 50px;
              padding: 15px 10px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 10px 10px 0 0;
              background: #D9E9F9;
              p {
                color: #0A2540;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0!important;
              }
            }
            &__Body {
              display: flex;
              padding: 15px 20px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 0 0 10px 10px;
              border: 1px solid #D9E9F9;
              background: #FFF;
              p {
                flex: 1 0 0;
                color: #0A2540;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0!important;
              }
            }
          }
        }
      }
    }

    &__Container {
      width: calc(100% - 400px);

      @media (max-width: $desktop-width) {
        width: 100%;
      }
    }
  }

  &__Confirm {
    display: flex;
    width: 843px;
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 auto;

    @media (max-width: $desktop-width) {
      width: 100%;
    }

    &__Title {
      display: flex;
      padding: 10px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      border-radius: 10px;

      h1 {
        color: #1579DB;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    &__Content {
      display: flex;
      padding: 10px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      border-radius: 10px;

      &__Link {
        display: flex;
        align-items: center;
        align-self: stretch;

        &__Header {
          display: flex;
          width: 250px;
          padding: 15px 10px;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          border-radius: 10px 0 0 10px;
          background: #D9E9F9;

          p {
            color: #0A2540;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            padding: 0;
          }
        }

        &__Body {
          display: flex;
          padding: 15px 20px;
          justify-content: space-between;
          align-items: center;
          flex: 1 0 0;
          border-radius: 0 10px 10px 0;
          border: 1px solid #D9E9F9;
          background: #FFF;

          p {
            color: #0A2540;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            padding: 0 10px 0 0;
          }
          &:first-child p {
            color : #1579DB!important;
          }
          i {
            color: #1579DB;
            font-size: 17.5px;
            cursor: pointer;
          }
        }
      }

      p {
        color: #0A2540;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  &__Caching {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $desktop-width) {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px;
    }

    ion-label {
      white-space: inherit !important;
      font-weight: 700;

      .accept {
        color: $blue;
      }

      p {
        font-weight: 400;
      }
    }

    .box{
      padding: 10px 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }

    .file__link {
      background: #f4f5f8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 5px 0;
      border-radius: 5px;
      padding: 10px 20px;
      height: 60px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);

      ion-button {
        i {
          margin: 0;
        }
      }

      i {
        margin: 0 10px 0 0;
        font-size: 18px;
      }

      p {
        margin: 0;
      }
    }

    .state {
      border-radius: 999px;
      padding: 5px 10px;
      border: 1px solid $black;
      position: relative;
      top: -20px;

      &.deny {
        border-color: $red;
        color: $red;
      }

      &.waiting,
      &.negotiating {
        color: $blue;
        border-color: $blue;
      }

      &.accepted {
        color: $green;
        border-color: $green;
      }
    }

    input {
      padding-left: 20px;
      width: 100%;
      height: 40px;
      display: flex;
      border-radius: 7px;
      border: transparent;
      background: $light-gray;

      &::placeholder {
        color: #b5bdc5;
      }
    }

    .payment__group__item .error_block {
      margin-top: 10px;
    }

    .payment__choices {
      background: transparent;
    }

    /* Styles pour la div principale */
    .amountNegociating {
      margin-bottom: 20px;
    }

    .amountNegociating label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .amountNegociating input[type="number"] {
      padding: 5px;
      margin-right: 10px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 3.5px;
      border-bottom-left-radius: 3.5px;
    }

    .amountNegociatingBlock {
      display: flex;
      width: 150px;
      align-items: center;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      padding-right: 12px;
    }


    .amountNegociating .errorNegociating {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }

    /* Style pour le label */
    label[for="tenantReason"] {
      display: block;
      margin-top: 20px;
      margin-bottom: 5px;
      font-weight: bold;
    }

    /* Style pour le champ de texte */
    input[type="text"]#tenantReason {
      padding: 5px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
      margin-bottom: 10px;
      border-radius: 4px;
    }

    &__Form {
      display: flex;
      width: 70%;
      padding: 40px 0;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      margin: 0 auto;
      overflow-y: auto!important;
      height: calc(100vh - 70px); // 70px is the height of the header
      -ms-overflow-style: none;
      scrollbar-width: none;

      @media (max-width: $desktop-width) {
        width: 85%;
        height: auto;
        overflow-y: hidden;
        padding: 20px 0;
      }

      .error_block {
        background-color: $light-red;
        border-radius: 10px;
        color: $red;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        margin-bottom: 10px;

        i {
          font-size: 27px;
          padding-right: 25px;
        }

        p {
          margin: 0;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .payment__data {
        padding: 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &__Header {
        width: 100%;
        text-align: center;
        margin-bottom: 100px;

        h2 {
          font-size: 28px;
          font-weight: 700;
        }

        p {
          width: 80%;
          margin: 0 auto;
          font-weight: 700;
        }

        @media screen and (max-width: $desktop-width) {
          margin-bottom: 20px;

          p {
            width: 100%;
          }
        }
      }

      &__Body {
        width: 100%;
        h3 {
          font-size: 22px;
          font-weight: 700;
          padding-top: 20px;
        }

        h4 {
          font-size: 18px;
          font-weight: 700;
          padding: 10px 0;
        }

        p {
          span {
            font-weight: 700;
          }
        }

        .vigilance {
          margin-top: 50px;

          ul {
            list-style-type: disc;
            padding: 0 0 0 40px !important;
          }
        }

        .dropzone {
          cursor: pointer;
          margin-top: 50px;
          background: $white;
          padding: 40px;
          text-align: center;
          border-radius: 7px;
          border: 1px solid rgba(18, 58, 76, 0.34);
          margin-bottom: 10px;
          transition: background 0.2s;

          &:hover {
            background-color: rgba(18, 58, 76, 0.1);
          }

          p {
            font-size: 14px;
            margin-bottom: 0;

            span {
              text-decoration: underline;
            }
          }
        }

        .comment {
          margin-top: 50px;

          label {
            font-size: 17px;
            font-weight: 700;
            display: block;
            margin-bottom: 10px;
          }

          textarea {
            padding: 15px;
            width: 100%;
            background-color: $white;
            min-height: 150px;
            border-radius: 5px;
            border: 1px solid rgba(18, 58, 76, 0.34);
          }

          p {
            margin-top: 30px;
            text-align: center;
          }
        }

        .action {
          display: flex;
          justify-content: left;
        }
      }
    }
  }

  &__Unavailable {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $desktop-width) {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px;
    }

    &__Container {
      display: flex;
      width: 60%;
      padding: 40px 0;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      margin: 0 auto;
      overflow-y: auto!important;
      height: calc(100vh - 70px); // 70px is the height of the header
      -ms-overflow-style: none;
      scrollbar-width: none;

      @media (max-width: $desktop-width) {
        width: 100%;
        height: auto;
        overflow-y: hidden;
        padding: 20px 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      h2 {
        text-align: center;
      }
    }
  }

  &__Check {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $desktop-width) {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px;
    }

    &__Form  {
      display: flex;
      width: 60%;
      padding: 40px 0;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      margin: 0 auto;
      overflow-y: auto!important;
      height: calc(100vh - 70px); // 70px is the height of the header
      -ms-overflow-style: none;
      scrollbar-width: none;

      @media (max-width: $desktop-width) {
        width: 100%;
        height: auto;
        overflow-y: hidden;
        padding: 20px 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &__Header {
        width: 100%;
        text-align: center;
        margin-bottom: 100px;

        h2 {
          font-size: 28px;
          font-weight: 700;
        }

        p {
          width: 80%;
          margin: 0 auto;
          font-weight: 700;
        }

        @media screen and (max-width: $desktop-width) {
          margin-bottom: 20px;

          p {
            width: 100%;
          }
        }
      }
    }
  }

  .activePercent {
    background-color: $blue !important;
  }
}
