.sign {
  .select {
    --padding-top: 12px;
    --padding-bottom: 12px;
  }

  &__background {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    position: absolute;

    .gray {
      height: 380px;
      background: $light-gray;

      @media screen and (max-width: $mobile-width) {
        height: 320px;
      }
    }

    .wave {
      width: 100%;
      position: absolute;
      top: 380px;

      @media screen and (max-width: $mobile-width) {
        top: 320px;
      }
    }
  }

  &__header {
    padding: 30px 90px;
  }

  &__content {
    padding-bottom: 100px;

    h1 {
      font-size: 29px;

      i {
        display: block;
        color: $blue;
        font-size: 36px;
        margin-bottom: 20px;

        @media screen and (max-width: $mobile-width) {
          margin-bottom: 10px;
          font-size: 26px;
        }
      }
    }

    h2 {
      color: $gray;
    }

    p {
      color: $gray;

      span {
        color: $dark-blue;
      }

      i {
        font-size: 32px;
        color: $dark-blue;
      }
    }

    .form-group-number {
      display: flex;
      justify-content: center;

      >div {
        display: flex;
      }
    }

    .form-number {
      margin: 0 10px;
      font-size: 47px;
      --padding-top: 30px;
      --padding-bottom: 30px;
      border-radius: 16px;

      @media screen and (max-width: $mobile-width) {
        --padding-top: 20px;
        --padding-bottom: 20px;
      }
    }

    .form-bottom {
      padding: 20px;
      position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;
      width: 100%;
      background: $white;
      bottom: 0;
      left: 0;

      .back,
      .dot,
      .next {
        width: 33.333%;
      }

      .back {
        text-align: right;
      }

      .next {
        text-align: left;
      }


      @include media-breakpoint-up(lg) {
        padding: 20px 40px;
      }

      .link {
        font-size: 20px;
      }
    }

    .dot {
      display: flex;
      justify-content: center;
      align-items: center;

      &__item {
        margin: 0 15px;
        width: 14px;
        height: 14px;
        border-radius: 9999px;
        background: #D0D8DB;

        &.active {
          background: $blue;
        }
      }
    }

    .resend {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $mobile-width) {
        display: block;
      }

      .link {
        margin: 0 0 0 10px;
      }
    }
  }
}

.end {
  .sign__background {
    .gray {
      @media screen and (max-width: $mobile-width) {
        height: 280px;
      }
    }

    .wave {
      @media screen and (max-width: $mobile-width) {
        top: 280px;
      }
    }
  }

  input {
    &.native-input {
      padding: 8px;
    }

    height: 46px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ced4da;
    background-color: white;
    width: 100%;
  }
}

.payment__lang {
  margin-top: 50px;
  max-width: 150px;
}