@import 'src/theme/variables';

.profil {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    justify-content: space-between;

    h2 {
      font-weight: 600;
    }
  }

  &__body {
    @media screen and (min-width: $desktop-width) {
      
    }

    @media screen and (max-width: $desktop-width) {
      .padding {
        padding-top: 30px;
      }
    }

    .block {
      margin-bottom: 40px;
      background: $white;
      border-radius: 30px;
      padding: 30px;

      h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      label {
        display: block;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      
      ion-input label {
        font-size: 14px;
        margin-bottom: 5px;
      }

      input,
      select,
      ion-select{
        padding: 10px 15px;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        font-size: 14px;
        background: $white;
        width: 100%;

        &:focus {
          outline: none;
        }
      }

      ion-select {
        padding: 0px 15px;
      }

      .password {
        input {
          border: 0!important;
        }
      }

      .ng-select.ng-select-single {
        .ng-select-container {
          height: 43px;

          .ng-value-container .ng-input {
            top: 10px;
          }
        }
      }

      .ng-select.ng-select-single .ng-select-container .ng-value-container,
      .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
        font-size: 14px;
      }
    }

    .payments {
      .title {
        display: flex;
        font-size: 14px;
        font-weight: 700;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .title.card-title {
        padding: 5px 20px 5px 10px;
        align-items: center;
        justify-content: space-between;
      }
    }

    .new-card {
      --border-radius: 99px;
      --padding-start: 30px;
      --padding-end: 30px;
      text-transform: none;
    }

    @media only screen and (max-width:$mobile-width) {

      .new-card {
        display: flex;
        justify-content: center;
      }
      .cb {
        justify-content: center;

        p {
          text-align: center;
        }

        .number {
          flex-direction: column;

          i {
            text-align: center;
          }
          span {
            text-align: center;
          }
        }
      }
    }

    .cb {
      display: flex;
      border: 1px solid #e2e7f0;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 5px 10px;
      align-items: center;
      justify-content: space-between;

      i {
        margin: 0 10px 0 0;
        font-size: 20px;
      }

      p {
        margin: 0;
        font-weight: 700;
        font-size: 14px;
      }

      .number {
        display: flex;
        align-content: center;
      }
    }
  }

  .right {
    background: $white;
    padding: 40px 20px 10px;
    border-radius: 30px;

    .active {
      color: $blue;
    }

    .avatar {
      position: relative;
      text-align: center;
      line-height: 100px;
      width: 100px;
      height: 100px;
      border-radius: 999px;
      border: 2px solid $blue;
      margin: 0 auto 15px;
      background-size: cover;
      background-color: lighten($blue, 50%);

      .fa-image {
        font-size: 30px;
        color: $blue;
      }

      .picture {
        height: 96px;
        width: 96px;
        border-radius: 999px;
        overflow: hidden;
      }

      button {
        bottom: 0;
        right: 0;
        position: absolute;
        line-height: 34px;
        z-index: 10;
        width: 34px;
        height: 34px;
        border-radius: 999px;
        background: $light-blue;
        color: $blue;
        transition: all 0.2s;

        &:hover {
          background: $blue;
          color: $white;
        }
      }

      input {
        display: none;
      }
    }

    h3,
    .pourcent {
      font-weight: 700;
      text-align: center;
    }

    h3 {
      font-size: 26px;
    }

    .pourcent {
      margin-top: 20px;
      font-size: 44px;
      color: $blue;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      i {
        font-size: 16px;
        margin: 0 15px 0 0;
        color: #ff8c42;
      }
    }
  }
}
