@import 'src/theme/variables';

.payment {
  overflow-y: auto;
  padding: 190px 0 100px;
  @media screen and (max-width: $desktop-width) {
    padding: 63px 0 0;
    background-color: $light-gray;
  }

  h1 {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
  }

  h2 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 16px;
  }

  &__background {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    position: absolute;

    .gray {
      height: 380px;
      background: $light-gray;
    }
    .wave {
      width: 100%;
    }
  }

  &__auth {
    margin-top: -190px;
    display: flex;
    @media screen and (max-width: $desktop-width) {
      display: block;
      margin-top: 0;
    }

    .side-bar,
    .sign-in {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: $desktop-width) {
        width: 100%;
        min-height: 35vh;
        height: fit-content;
        padding: 20px;
      }

      .center {
        text-align: center;
        width: 375px;

        .link {
          color: $white;
          margin-bottom: 20px;
        }
      }

      i {
        font-size: 30px;
        margin-bottom: 30px;
      }

      h2 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }

    .side-bar {
      width: 20%;
      background-color: $light-gray;
    }

    .sign-in {
      color: $white;
      background-color: $white;
      width: 80%;

      .form-group {
        margin-bottom: 15px;
        text-align: left;

        label {
          display: block;
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 700;
        }

        input {
          border-radius: 5px;
          color: $black;
          display: block;
          background-color: $white;
          border: none;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 1060px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $desktop-width) {
      flex-direction: column-reverse;
    }
  }

  &__left {
    width: calc(40% - 90px);
    @media screen and (max-width: $desktop-width) {
      width: 100%;
      h2 {
        margin: 15px 30px;
      }
    }
  }

  &__right {
    width: 60%;
    @media screen and (max-width: $desktop-width) {
      width: 100%;
    }
  }

  &__stepper {
    display: flex;
    border-radius: 7px;
    margin-bottom: 30px;
    background: $white;
    border: 1px solid #70707033;

    &.tablet {
      margin-top: 10px;
    }

    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33.33333%;
      color: #123a4c72;
      height: 56px;
      font-size: 12px;
      font-weight: 700;

      i {
        margin: 0 10px 0 0;
      }

      &:nth-child(2) {
        border-right: 1px solid #70707033;
        border-left: 1px solid #70707033;
      }

      &.active {
        color: $dark-blue;
        background: #e2e7f0;

        border: 2px solid var(--ion-color-primary);
        border-radius: 3px;
      }
    }

    &.two {
      .step{
        width: 50%;
      }
    }
  }

  &__form {
    margin-top: 20px;
    background: $light-gray;
    padding: 15px 30px;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    h3 {
      font-size: 20px;
      width: 100%;
      margin-bottom: 15px;
      font-weight: 700;

      span {
        margin: 0 0 0 10px;
        font-size: 12px;
        color: $blue;
      }
    }
  }

  &__group {
    width: 48%;
    margin-bottom: 15px;

    @media screen and (max-width: $desktop-width) {
      width: 100%;
    }

    label {
      display: block;
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 700;

      span {
        margin: 0 0 0 10px;
        font-size: 11px;
        font-weight: 700;
        color: $blue;
      }
    }

    .ng-select {
      .ng-select-container {
        border: none;
        border-radius: 7px;
      }
    }

    .ng-select.ng-select-single {
      .ng-select-container {
        height: 44px;

        .ng-value-container .ng-input {
          top: 10px;
        }
      }
    }

    input,
    ion-select {
      padding: 10px;
      border-radius: 7px;
      border: none;
      width: 100%;
      background: $white;
    }

    &.full {
      width: 100%;
    }

    .email__input {
      position: relative;

      i {
        position: absolute;
        bottom: 13px;
        font-size: 18px;
        right: 15px;

        &.fa-check-circle {
          color: $blue;
        }

        &.fa-times-circle {
          color: $red;
        }
      }
    }
  }

  .auth__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .checkbox {
      --border-width: 1px;
    }

    label {
      font-weight: 700;
      margin: 0 5px 0 20px;
      .link {
        color: $blue;
        text-decoration: none;
      }
    }
  }

  .one-line {
    justify-content: space-between;
    display: flex;

    ion-select,
    input {
      width: 48%;
    }
  }

  &__data {
    margin-bottom: 5px;
    display: flex;
    @media screen and (max-width: $desktop-width) {
      flex-direction: column;
      padding: 0 30px;
      margin-bottom: 10px;
    }

    h5 {
      margin: 0;
      padding: 15px 30px 15px 30px;
      border-radius: 7px 0 0 7px;
      width: 33%;
      @media screen and (max-width: $desktop-width) {
        width: 100%;
        border-radius: 7px 7px 0 0;
      }
      font-size: 12px;
      font-weight: 700;
      background: #e2e7f0;
    }

    p {
      margin: 0;
      background: $white;
      padding: 15px 30px 15px 30px;
      border-radius: 0 7px 7px 0;
      width: 67%;
      @media screen and (max-width: $desktop-width) {
        width: 100%;
        border-radius: 0 0 7px 7px;
      }
      border: 1px solid #e2e7f0;
      font-size: 12px;

      span {
        font-size: 16px;
        margin: 0 5px 0 0;
        color: $blue;
      }
    }

    &.reverse {

      p {
        margin: 0;
        padding: 15px 30px 15px 30px;
        border-radius: 7px 0 0 7px;
        width: 33%;
        @media screen and (max-width: $desktop-width) {
          width: 100%;
          border-radius: 7px 7px 0 0;
        }
        font-size: 12px;
        background: #e2e7f0;
        font-weight: 700;
      }

      h5 {
        font-weight: 400;
        margin: 0;
        background: $white;
        padding: 15px 30px 15px 30px;
        border-radius: 0 7px 7px 0;
        width: 67%;
        @media screen and (max-width: $desktop-width) {
          width: 100%;
          border-radius: 0 0 7px 7px;
        }
        border: 1px solid #e2e7f0;
        font-size: 12px;

        span {
          font-size: 16px;
          margin: 0 5px 0 0;
          color: $blue;
        }
      }
    }

    &.long {
      h5 {
        height: auto;
      }

      p {
        padding: 10px 30px;
        white-space: unset;
        overflow: auto;
        text-overflow: none;
        line-height: 20px;
        height: auto;

        span {
          color: $blue;
          padding: 0 0 0 30px;
        }
      }
    }
  }

  &__action {
    width: 100%;
    display: flex;
    justify-content: center;

    ion-button {
      text-transform: none;
      font-weight: 700;
      font-size: 16px;
      --border-radius: 999px;
      --padding-top: 20px;
      --padding-bottom: 20px;
      --padding-start: 60px;
      --padding-end: 60px;
    }
  }

  &__owner {
    border-radius: 7px;
    background: $white;
    padding: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

    &.light {
      margin: 0 auto 20px;
      box-shadow: none;
      border: 1px solid #e2e7f0;
      /*width: 50%;*/
    }

    .avatar {
      width: 72px;
      height: 72px;
      overflow: hidden;
      background: #f2f2f2;
      display: flex;
      align-items: center;
      font-size: 30px;
      justify-content: center;
      border-radius: 9999px;
    }

    .information {
      margin: 0 0 0 20px;
    }

    h5 {
      margin: 0;
      font-weight: 700;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      margin: 0;
    }
  }

  &.user {
    .payment {
      &__body {
        width: 655px;
        margin: 0 auto;
        @media screen and (max-width: $desktop-width) {
          width: 100%;
        }

        h2 {
          text-align: center;
        }
      }

      &__stepper {
        width: 440px;
        margin: 0 auto 30px;
        @media screen and (max-width: $desktop-width) {
          width: 100%;
          margin: 10px 0;
        }
        .step {
          width: 50%;
        }
      }

      &__choices {
        margin-top: 30px;
      }

      &__group {
        &__item {
          display: flex;
          border-radius: 5px;
          border: 1px solid #e2e7f0;
          margin-bottom: 10px;

          @media screen and (max-width: $desktop-width) {
            flex-direction: column;
            margin: 10px 30px;
          }

          ion-item {
            width: 33%;
            @media screen and (max-width: $desktop-width) {
              width: 100%;
            }
            --border-color: #e2e7f0;
            --background: #e2e7f0;
            background: #e2e7f0;

            ion-label {
              font-size: 12px;
            }

            ion-radio {
              margin: 11px 20px 10px 4px !important;
            }
          }

          p {
            margin: 0;
            padding: 15px;
            width: 67%;
            @media screen and (max-width: $desktop-width) {
              width: 100%;
            }
            font-size: 12px;

            ion-button {
              line-height: 12px;
              height: 16px;
              font-size: 12px;
            }
          }

          .data {
            width: 67%;
            user-select: auto;
            @media screen and (max-width: $desktop-width) {
              width: 100%;
            }
            p {
              width: 100%;
            }
          }

          .divide {
            display: flex;
            align-items: center;
            padding: 0 15px 15px;

            ion-button {
              font-size: 16px;
              --padding-end: 10px;
              --padding-start: 10px;
            }

            span {
              margin: 0 10px;
              font-size: 12px;
            }

            .calc {
              color: rgba(18, 58, 76, 0.45);
            }
          }
        }
      }
    }
  }
  .tablet {
    .tyllt {
      border-radius: 0;
    }
  }
}
