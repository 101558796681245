@import "src/theme/variables";
@import "src/theme/utils/mixins";

.bond {
  &__header {
    display: flex;

    .icon {
      width: 122px;
      margin: 0 40px 0 0;
    }

    .info {
      width: calc(100% - 162px);
    }

    h1 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      line-height: 20px;
      font-size: 16px;

      span {
        font-weight: 700;
      }
    }
  }

  &__title {
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $desktop-width) {
      margin-top: 10px;
    }

    @media screen and (max-width: $mobile-width) {
      margin: 0;
    }

    h2 {
      font-weight: 700;
      font-size: 20px;

      i {
        margin: 0 10px 0 0;
      }
    }

    ion-button {
      width: 300px;
    }

    .action {
      ion-button {
        --padding-start: 30px;
        --padding-end: 30px;
        text-transform: none;
        border-radius: 999px;
        --border-radius: 999px;
        margin: 0 0 0 20px;
        width: auto;

        i {
          @include unselectable();
          @include no-pointer-events();
        }

        @media screen and (max-width: $mobile-width) {
          width: 100%;
          margin: 0 0 20px 0;
        }

        i {
          margin: 0 0 0 15px;
        }
      }
    }
  }

  &__body {
    overflow: auto;
    margin-bottom: 40px;

    #amount {
      border-radius: 7px 0 0 7px;
      border: none;
    }

    label {
      font-size: 14px;
      margin-bottom: 10px;
      font-weight: 700;
    }

    .form-group {
      margin-bottom: 20px;
    }

    ion-input {
      --padding-start: 20px;
    }

    .input__container {
      width: 50%;

      @media screen and (max-width: $desktop-width) {
        width: 100%;
      }

      height: 40px;
      display: flex;
      border-radius: 7px;
      background: $white;

      &.full {
        width: 100%;
        position: relative;

        .input-length {
          position: absolute;
          right: 8px;
          bottom: 4px;
          font-size: 0.8em;
          color: $light-blue;

          &.error {
            color: $red;
          }
        }
      }
    }

    .money {
      width: 110px;
      text-align: center;
      font-weight: 700;
      line-height: 40px;
      font-size: 21px;
      border-radius: 0 7px 7px 0;
      background: #E2E7F0;
    }

    h2 {
      margin-top: 50px;
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 700;
    }

    .transaction {
      border-radius: 7px;
      padding: 40px;

      @media screen and (max-width: $desktop-width) {
        padding: 20px;
      }

      background: $white;

      &__header {
        display: flex;
        font-size: 12px;
        margin-bottom: 20px;
        justify-content: space-between;
      }
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .checkbox {
      --border-width: 1px;
    }

    label {
      margin: 0 5px 0 20px;
    }

    .link {
      color: $blue;
      text-decoration: none;
    }
  }

  .new {
    width: 280px;
    margin-bottom: 40px;
  }

  .bond__form,
  .owner__form,
  .product__form {
    border-radius: 7px;
    padding: 30px 20px 10px;

    h3 {
      font-size: 18px;
      margin-bottom: 30px;
    }

    h4,
    label {
      font-size: 14px;
    }

    h4 {
      margin-bottom: 15px;

      b {
        color: $blue;
      }
    }

    ion-select,
    ion-input {
      --padding-top: 18px;
      --padding-bottom: 18px;
      border: 1px solid $input-gray;
      border-radius: 7px;
    }
  }

  .bond__form {
    background: $white;
    margin-bottom: 20px;

    ion-button {
      --background: $input-gray !important;

      &.checked {
        --background: $blue !important;
      }
    }
  }

  .owner__form {
    margin-bottom: 20px;
    background-color: #BAC2C6;

    ion-input {
      background-color: $white;
    }
  }

  .product__form {
    margin-bottom: 30px;
    background-color: $input-gray;

    ion-select,
    ion-input {
      --background: $white;
      background-color: $white;
    }
  }

  .user__container {
    padding: 20px 20px 15px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    border-radius: 7px;
    background-origin: border-box;
    background: url("/assets/svg/wave.svg") no-repeat top right $white;
    background-size: auto 100%;

    h5 {
      font-size: 14px;
      font-weight: 700;
    }

    p {
      margin-bottom: 0;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      color: $black;
    }

    .trx-btn {
      color: $blue;
      margin-top: 20px;
      font-weight: 700;
      display: block;
    }

    .download {
      text-decoration: none;
      color: $dark-blue;
      font-weight: 700;

      span {
        margin: 0 0 0 10px;
        text-align: center;
        line-height: 34px;
        display: inline-block;
        width: 34px;
        height: 34px;
      }
    }
  }
}